import React from 'react'
import { useNavigate } from 'react-router-dom'

function PopularBlogCard({
    img,
    title,
    date,
    anchor,
    redirectionFunction
}) {

  const navigate = useNavigate("/")
  return (
    <div className='popular-card d-flex  gap-3 align-items-center my-3 py-1' onClick={()=>{redirectionFunction(anchor)}}>
        <img src={img} className='popular-blog-img'/>
        <div className='blog-details d-flex flex-column gap-3'>
            <h5 className='card-title'>{title}</h5>
            <div className="d-flex gap-2 align-items-center">
            <img src="/images/icons/tl-blog-logo.png" alt="icon" className="blog_list_logo_img" style={{width:"30px",height:"30px"}}/>
            <h6 className="blog_list_logo_title m-0" style={{fontSize:"14px"}}>True Life Wellness</h6>
          </div>
        </div>
    </div>
  )
}

export default PopularBlogCard