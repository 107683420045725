import React from 'react'
import { useNavigate } from 'react-router-dom';


function ServiceCard({
    icon,
    heading,
    content,
    anchor,
}) {
  const navigate = useNavigate()
  const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-1'>{paragraph}</p>);
  return (
    <div className="service-card-box py-4">
    <div className="service-card-info">
      
      <div className="profile-info px-2 d-flex flex-column gap-3 text-center ">
          <img src={icon} alt='card-icon' className='card-round-icon' />
          <h3 className='body-heading mt-3' style={{fontSize:"20px"}}>{heading}</h3>
          <p className="body-paragraph px-4">{paragraphs}</p>

          <p className='body-paragraph card-anchor' onClick={()=>{navigate(anchor)}}>Read More</p>
          
      </div>
     
    </div>
</div>
  )
}

export default ServiceCard