import React from 'react'
import "./TestimonialForm.css"
import Testimonials from './Testimonials/Testimonials'
import Contactform from './Contact Form/Contactform'

function TestimonialForm({
  marginBottom=true
}) {
  return (
    <div className={`testimonial-form-container standard-margin-space ${!marginBottom && "mb-0 testimonial-bg-slate"} `}>
        <Testimonials/>
        <Contactform/>
    </div>
  )
}

export default TestimonialForm