import React from 'react'
import "./NewsCard.css"

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}

function NewsCard(props) {
  const title = truncateText(props.title,50)
  const breifPara = truncateText(props.para,70)
  return (
    <div className="news_card pb-3 h-100 " onClick={()=>{props.redirectionFunction(props.anchor)}}>
      <img src={props.img} className="card_img img-fluid" alt="blog thumbnail" />
      <div className="card_text d-flex flex-column align-items-start justify-content-between">
        <h3 className="h3_main mt-3 mb-0">
          <span className="sub-heading">Latest News</span>
        </h3>
        <h4 className={`h4_main my-auto`}>
          <span className="card_title body-heading">{title}</span>
          <p className='body-paragraph my-3'>{breifPara}</p>
        </h4>
        <div className="blog_list_logo">
          <img src="/images/icons/tl-blog-logo.png" alt="icon" className="blog_list_logo_img"/>
          <div className="blog_list_logo_text ">
            <h6 className="blog_list_logo_title m-0">True Life Wellness</h6>
            <p className="blog_list_logo_date m-0">{props.date}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewsCard