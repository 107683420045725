import React,{useState,useEffect} from "react";
import "./CardBlog.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import CardBox from "./CardBox";
import { useNavigate } from "react-router-dom";
import axios from "axios"

let defaultBlogObj = []

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    const lastSpaceIndex = text.lastIndexOf(' ', maxLength - 4);
    return text.substring(0, lastSpaceIndex) + '...';
  }
  return text;
}

function CardTestimonial() {
  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Creative Squad",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          if(blogs.length <= 3)
          {
            setBlogs((prev)=>[...prev,...prev])
          }
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        console.log("eror =>",err);
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/blogs/${slug}`);
  };

  const blogData = [
    {
      cardImg: "./images/creatives/blog-card-img1.png",
      title: "Understanding Shockwave Therapy: A Breakthrough in Pain Management",
      content:
        "Explore the benefits of shockwave therapy for chronic pain conditions such as plantar fasciitis, tendonitis, and more. Learn how this innovative treatment modality can help accelerate healing and improve your quality of life.",
    },
    {
      cardImg: "./images/creatives/blog-card-img2.png",
      title: "The Role of Nutrition in Rehabilitation: Fueling Your Recovery",
      content:
        "Discover the importance of nutrition in supporting your body's healing process. Our experts share practical tips and dietary guidelines to optimize your recovery and enhance your overall well-being.",
    },
    {
      cardImg: "./images/creatives/blog-card-img3.png",
      title: "Overcoming Challenges: Inspirational Stories from True Life Wellness and Physiotherapy Patients",
      content:
        "Be inspired by real-life stories of resilience, determination, and triumph over adversity. Meet some of our patients who have overcome obstacles and achieved remarkable results.",
    },
    {
    
      cardImg: "./images/creatives/blog-card-img2.png",
      title: "The Role of Nutrition in Rehabilitation: Fueling Your Recovery",
      content:
        "Discover the importance of nutrition in supporting your body's healing process. Our experts share practical tips and dietary guidelines to optimize your recovery and enhance your overall well-being.",
    },
    
  ];

  return (
    <div className='blog-container container standard-padding-space my-4'>
      <div className="blog-upper-content">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="blog-text-content text-center">
                <p className="sub-heading">Our Blogs</p>
                <h3 className="body-heading">Wellness Wisdom & Insights</h3>   
                
              </div>
            </div>
            
          </div>
        </div>
      </div>

        <Swiper
            loop={true}
            spaceBetween={50}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
            className="card-list py-4 mx-0 w-100 px-2"
            >
            {blogs.map((blogData,ind)=>{
              return (<>
                <SwiperSlide key={ind} style={{height:"auto"}}>
                  <CardBox className="h-100"  cardImg={blogData.blog_creative} title={blogData.main_heading} readMoreRef={blogData.slug_url} content={truncateText(blogData.paragraph , 90)} redirection={redirectUserToBlog}/>
                </SwiperSlide>
              </>)
            })}
            
        </Swiper>
    </div>
  )
}

export default CardTestimonial