import React,{useEffect,useState} from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import BlogDetails from '../component/BlogDetails/BlogDetails'
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Footer from '../component/Footer/Footer';

const dummyBlogData = 
  {
    title:"How to Master Your Assessment of Athletic Low Back Pain",
    blogs_content: [
      {
        "heading": "Lorem Ipsum is simply dummy text of the printing and typesetting",
        "paragraph": "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>",
        "_id": {
          "$oid": "65bc62646b439aa605d0af27"
        }
      },
      {
        "heading": "Lorem Ipsum is simply dummy text",
        "paragraph": "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>",
        "_id": {
          "$oid": "65bc62646b439aa605d0af28"
        }
      },
      {
        "heading": "Lorem Ipsum is simply dummy text",
        "paragraph": "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>",
        "_id": {
          "$oid": "65bc62646b439aa605d0af29"
        }
      }
    ],
    published_date: "12 feb 2024",
    brief_paragraph:""
  }
  const recentDummyBlogsData = [
    {
      slug_url: "",
      blog_creative: "./images/creatives/popular-blog-1.png",
      blog_description: "",
      date: "Apr 10, 2023",
      main_heading: "Lorem Ipsum is simply dummy text loremIpsum is simply",
    },
    {
      slug_url: "",
      blog_creative: "./images/creatives/popular-blog-2.png",
      blog_description: "",
      date: "Apr 10, 2023",
      main_heading: "Lorem Ipsum is simply dummy text loremIpsum is simply",
    },
    {
      slug_url: "",
      blog_creative: "./images/creatives/popular-blog-3.png",
      blog_description: "",
      date: "Apr 10, 2023",
      main_heading: "Lorem Ipsum is simply dummy text loremIpsum is simply",
    },
  ]

function NewsInner() {
  
  const [blogsData, setBlogsData] = useState();
  const [recentBlogs, setRecentBlogs] = useState();
  
  const [loading, setLoading] = useState(true);
  
  const navigate = useNavigate()
  
  const { id } = useParams();

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async (slug) => {
    if (allBlogsCalled) return;
    allBlogsCalled = true;

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_creative: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
              main_heading: item.title,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          let blog = res.data.data.find((item) => item.slug_url == slug);
          setBlogsData(blog);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    
      getAllBlogs(id);
      // setBlogsData(dummyBlogData);
  },[id]);

     
  return (
    <>



{loading && !blogsData  ? (<>
       
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: " #29e555", width: "120px", height: "120px" }}
            />
          </div>
          </>) : (
          blogsData && (
            <>
              <HeroBanner
                subHeading=""
                Heading={blogsData.title}
                spanHeading=""
                Paragraph={`By: True Life Wellness | Published On: ${blogsData.published_date} | Categories: blog`}
                darkBg={true}
                btnText=""
                bgImg="/images/creatives/inner-news-bg.jpg"
                btn={false}
                statSection={false}
                socialLinks={false}
                serviceForm={false}
                sideImage={false}
              />
              {recentBlogs && <BlogDetails
                blogsData={blogsData.blogs_content}
                datePublished={blogsData.published_date}
                text={blogsData.brief_paragraph}
                recentBlogs={recentBlogs}
                
              />}

              <Footer
                newsletterSection={false}
              />
            </>
          )
        )}
         
    </>
  ) 
}

export default NewsInner