import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function NotFound() {
  return (
    <div>
        <HeroBanner 
            subHeading=""
            Heading="404"
            nextLineSpanHeading=""
            Paragraph="Could not found"
            btn={false}
            btnText=""
            bgImg="/images/creatives/plane-hero-bg.png"
            btnRef="/"
            bannerMarginBottom={true}
          />

          <Footer
            newsletterSection={false}
          />
    </div>
  )
}

export default NotFound