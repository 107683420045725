import React, { useEffect, useState } from 'react'
import "./KeyFeatureText.css"
import { useNavigate } from 'react-router-dom'

const changingTabData = [
    {
        id:1,
        heading:"Physiotherapy",
        url:"/service/physiotherypy",
        code:`
        <h3 class="body-heading">Physiotherapy</h3>
        <p class="body-paragraph">At True Life Wellness and Physiotherapy, our physiotherapy services are tailored to address a wide range of musculoskeletal issues and promote optimal physical function and mobility. Our licensed physiotherapists work closely with each patient to develop personalized treatment plans that target their specific needs and goals. Through a combination of manual therapy techniques, therapeutic exercises, and modalities such as shockwave therapy and cold laser therapy, we aim to:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph">Reduce pain and inflammation.</p></li>
            <li><p class="body-paragraph">Improve joint flexibility and range of motion.</p></li>
            <li><p class="body-paragraph">Strengthen muscles and enhance stability.</p></li>
            <li><p class="body-paragraph">Facilitate recovery from injuries, surgeries, and chronic conditions.</p></li>
            <li><p class="body-paragraph">Restore functional independence and quality of life.</p></li>
        </ul>
        
        <p class="body-paragraph">Whether you are recovering from a sports injury, managing a chronic condition like arthritis, or seeking rehabilitation after surgery, our physiotherapy services can help you regain confidence in your body and get back to doing the things you love.</p>
        
        `
    },
    {
        id:2,
        heading:"Chiropractic Treatment",
        url:"/service/chiropractic-treatment",
        code:`
        <h3 class="body-heading">Chiropractic Treatment</h3>
        <p class="body-paragraph">Chiropractic treatment at True Life Wellness and Physiotherapy focuses on restoring proper alignment to the spine and nervous system, thereby relieving pain, improving function, and enhancing overall health and well-being. Our experienced chiropractors use gentle, hands-on adjustments to correct spinal misalignments and address issues such as:</p>

            <ul class="custom-green-bullet-list">
                <li><p class="body-paragraph">Back pain and sciatica (pain down the leg).</p></li>
                <li><p class="body-paragraph">Neck and mid back pain.</p></li>
                <li><p class="body-paragraph">Headaches.</p></li>
                <li><p class="body-paragraph">Joint stiffness and discomfort.</p></li>
                <li><p class="body-paragraph">Postural imbalances.</p></li>
                <li><p class="body-paragraph">Temporomandibular joint dysfunction (TMJ).</p></li>
            </ul>

            <p class="body-paragraph">By realigning the spine and promoting optimal nervous system function, chiropractic treatment can help alleviate pain, improve mobility, and support the body's natural healing processes. Whether you are seeking relief from acute discomfort or looking to optimize your overall health, our chiropractic services offer safe, effective solutions for patients of all ages.</p>

        `
    },
    {
        id:3,
        heading:"Registered Massage Therapy",
        url:"/service/registered-massage-therapy",
        code:`
        <h3 class="body-heading">Registered Massage Therapy</h3>
        <p class="body-paragraph">Registered massage therapy at True Life Wellness and Physiotherapy offers a holistic approach to healing, combining therapeutic touch with evidence-based techniques to address both physical and emotional well-being. Our registered massage therapists specialize in various techniques, including Swedish massage, deep tissue massage, sports massage, and trigger point therapy, to:</p>

            <ul class="custom-green-bullet-list">
                <li><p class="body-paragraph">Reduce muscle tension and stiffness.</p></li>
                <li><p class="body-paragraph">Alleviate pain and discomfort.</p></li>
                <li><p class="body-paragraph">Improve circulation and lymphatic drainage.</p></li>
                <li><p class="body-paragraph">Promote relaxation and stress relief.</p></li>
                <li><p class="body-paragraph">Enhance overall physical and mental well-being.</p></li>
            </ul>

        <p class="body-paragraph">Whether you are seeking relief from chronic pain, recovering from an injury, or simply looking to unwind and relax, our massage therapy services can provide the healing touch you need to feel your best.</p>

        `
    },
    {
        id:4,
        heading:"Acupuncture",
        url:"/service/acupuncture",
        code:`
        <h3 class="body-heading">Acupuncture</h3>
        <p class="body-paragraph">Whether you are seeking relief from chronic pain conditions like arthritis or fibromyalgia, managing stress-related symptoms, or looking to support your overall health and wellness, acupuncture can offer safe, natural solutions that complement your existing treatment plan. At True Life Wellness and Physiotherapy, our licensed acupuncturist utilize this ancient healing modality to:</p>

            <ul class="custom-green-bullet-list">
                <li><p class="body-paragraph">Reduce pain and inflammation.</p></li>
                <li><p class="body-paragraph">Improve circulation and nerve function.</p></li>
                <li><p class="body-paragraph">Alleviate stress and anxiety.</p></li>
                <li><p class="body-paragraph">Enhance immune function.</p></li>
                <li><p class="body-paragraph">Restore balance to the body and mind.</p></li>
                <li><p class="body-paragraph">Infertility, irregular menstruation, uterine bleeding, low sperm motility</p></li>
                <li><p class="body-paragraph">Insomnia, anxiety, depression, stress</p></li>
                <li><p class="body-paragraph">Tinnitus, vertigo, dizziness, headaches, blurry vision, light headedness, low energy, poor memory</p></li>
                <li><p class="body-paragraph">Insufficient breastmilk, morning sickness, inducing labour</p></li>
                <li><p class="body-paragraph">Chemotherapy symptoms (nausea, vomiting, low energy, low WBC)</p></li>
                <li><p class="body-paragraph">Bloating, acid reflux, indigestion, poor appetite, weight loss</p></li>
                <li><p class="body-paragraph">Urinary incontinence, Urinary retention</p></li>
                <li><p class="body-paragraph">Sinus congestion, allergies, shortness of breath, Bals Palsy, TMJ, Alopecia, psoriasis, eczema</p></li>
            </ul>

        `
    },
    {
        id:5,
        heading:"Psychology (Virtual)",
        url:"/service/psychology",
        code:`
        <h3 class="body-heading">Psychology (Virtual)</h3>
        <p class="body-paragraph">Our virtual psychological counselling at True Life Wellness and Physiotherapy provides confidential and convenient access to mental health support from the comfort of your own home. Our licensed psychologists offer a range of therapeutic interventions and counseling techniques to help you:</p>

            <ul class="custom-green-bullet-list">
                <li><p class="body-paragraph">Manage stress, anxiety, and depression.</p></li>
                <li><p class="body-paragraph">Navigate life's transitions and relationship challenges.</p></li>
                <li><p class="body-paragraph">Improve coping skills and resilience.</p></li>
                <li><p class="body-paragraph">Overcome trauma and past experiences.</p></li>
                <li><p class="body-paragraph">Enhance self-awareness and personal growth.</p></li>
            </ul>

            <p class="body-paragraph">Through secure video conferencing sessions, you can receive the support and guidance you need to address emotional difficulties, explore solutions, and develop strategies for positive change. Whether you are facing a specific mental health concern or seeking support for personal growth and self-discovery, our virtual psychological counselling offers flexible, accessible care that fits your needs.</p>

        `
    },
    {
        id:6,
        heading:"Naturopathic Medicine",
        url:"/service/naturopathic-medicine",
        code:`
        <h3 class="body-heading">Naturopathic Medicine</h3>
        <p class="body-paragraph">Naturopathic medicine at True Life Wellness and Physiotherapy takes a holistic approach to health and wellness, focusing on the underlying causes of illness and addressing the whole person – body, mind, and spirit. Our licensed naturopathic doctors offer a variety of natural therapies and treatment modalities to:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph">Support the body's innate healing abilities.</p></li>
            <li><p class="body-paragraph">Optimize nutrition and dietary habits.</p></li>
            <li><p class="body-paragraph">Balance hormones and improve hormonal health.</p></li>
            <li><p class="body-paragraph">Detoxify the body and support organ function.</p></li>
            <li><p class="body-paragraph">Enhance overall vitality and well-being.</p></li>
        </ul>

        <p class="body-paragraph">From botanical medicine and nutritional supplementation to lifestyle counseling and acupuncture, our naturopathic services provide personalized, integrative care that empowers you to take charge of your health and live your best life.</p>

        `
    },
    {
        id:7,
        heading:"Custom Made Orthotics and Shoes",
        url:"/service/custom-made-orthotics-and-shoes",
        code:`
        <h3 class="body-heading">Custom Made Orthotics and Shoes</h3>
        <p class="body-paragraph">Custom-made orthotics and shoes at True Life Wellness and Physiotherapy are designed to provide personalized support and alignment for your feet, ankles, knees, lower back, etc. Our trained chiropractors can offer custom-made orthotics and shoes to:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph">Correct foot deformities and alignment issues.</p></li>
            <li><p class="body-paragraph">Alleviate pain and discomfort associated with conditions like plantar fasciitis, heel spurs, arthritis, etc.</p></li>
            <li><p class="body-paragraph">Improve balance and stability.</p></li>
            <li><p class="body-paragraph">Prevent injuries and promote efficient movement patterns.</p></li>
            <li><p class="body-paragraph">Enhance overall foot health and function.</p></li>
        </ul>

        <p class="body-paragraph">Whether you are dealing with chronic foot pain, recovering from a sports injury, or seeking preventive care for optimal biomechanical function, our custom-made orthotics and shoes can provide the support you need to stay active, mobile, and pain-free.</p>

        `
    },
    {
        id:8,
        heading:"Custom Made Braces",
        url:"/service/custom-made-braces",
        code:`
        <h3 class="body-heading">TrueLife Custom Braces: <span class="color-green">Reclaim Your Mobility with a Perfect Fit</span></h3>
        <p class="body-paragraph">Injuries and surgeries can sideline you from the activities you love. At TrueLife, we understand the importance of getting back to your life quickly and safely. That's why we offer custom-made braces designed to:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph"><strong>Support Healing: </strong>Our braces provide targeted support to stabilize your injury and promote proper healing.</p></li>
            <li><p class="body-paragraph"><strong>Improve Function: </strong>Regain mobility and range of motion with a brace that allows for controlled movement.</p></li>
            <li><p class="body-paragraph"><strong>Prevent Further Injury: </strong>Reduce the risk of re-injury by ensuring proper joint alignment and stability.</p></li>
        </ul>

        <br></br>
        <h3 class="body-heading">The TrueLife Difference:</h3>
        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph"><strong>Custom-crafted for You: </strong>Unlike one-size-fits-all braces, ours are made specifically for your body and injury for a perfect fit and optimal results.</p></li>
        </ul>


        `
    },
    {
        id:9,
        heading:"Pelvic Floor Physiotherapy",
        url:"/service/pelvic-floor-physiotherapy",
        code:`
        <h3 class="body-heading">Pelvic Floor Physiotherapy</h3>
        <p class="body-paragraph">Pelvic floor physiotherapy at True Life Wellness and Physiotherapy offers specialized care for conditions affecting the pelvic region, including pelvic pain, urinary and bowel incontinence, and pelvic floor muscle weakness. Our trained physiotherapist uses targeted exercises and manual therapy techniques to:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph">Strengthen the pelvic floor muscles.</p></li>
            <li><p class="body-paragraph">Improve bladder and bowel function.</p></li>
            <li><p class="body-paragraph">Alleviate pelvic pain and discomfort.</p></li>
            <li><p class="body-paragraph">Enhance sexual health and intimacy.</p></li>
            <li><p class="body-paragraph">Support recovery from pregnancy and childbirth.</p></li>
        </ul>

        <p class="body-paragraph">Whether you are experiencing symptoms related to pregnancy, menopause, aging, or other pelvic health concerns, our pelvic floor physiotherapy services can provide effective solutions to improve your quality of life and restore confidence in your body.</p>

        `
    },
    {
        id:10,
        heading:"Concussion Rehabilitation",
        url:"/service/concussion-rehabilitation",
        code:`
        <h3 class="body-heading">Concussion Rehabilitation</h3>
        <p class="body-paragraph">Concussion rehabilitation at True Life Wellness and Physiotherapy offers comprehensive care for individuals recovering from mild to severe traumatic brain injuries. Our specialized rehabilitation programs are designed to:</p>

            <ul class="custom-green-bullet-list">
                <li><p class="body-paragraph">Manage symptoms such as headaches, dizziness (vertigo), and cognitive deficits.</p></li>
                <li><p class="body-paragraph">Restore balance, coordination, and motor function.</p></li>
                <li><p class="body-paragraph">Improve cognitive function and memory.</p></li>
                <li><p class="body-paragraph">Facilitate safe return to work, school, and daily activities.</p></li>
                <li><p class="body-paragraph">Support emotional well-being and mental health.</p></li>
            </ul>

            <p class="body-paragraph">Whether you have experienced a concussion as a result of a sports-related injury, motor vehicle accident, or other traumatic event, our multidisciplinary team of healthcare professionals can provide the personalized care and support you need to recover fully and regain independence.</p>

        `
    },
    {
        id:11,
        heading:"Shockwave Therapy",
        url:"/service/shockwave-therapy",
        code:`
        <h3 class="body-heading">Shockwave Therapy</h3>
        <p class="body-paragraph">Shockwave therapy at True Life Wellness and Physiotherapy offers a non-invasive treatment for a variety of musculoskeletal conditions, including chronic pain, tendonitis (jumper’s knee and tennis elbow), heel spurs, plantar fasciitis, etc.</p>
        <p class="body-paragraph">Shockwave therapy uses non-invasive high intensity acoustic shock waves to stimulate the body's natural healing processes, in order to:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph">Reduce pain and inflammation.</p></li>
            <li><p class="body-paragraph">Promote tissue regeneration and repair.</p></li>
            <li><p class="body-paragraph">Improve circulation and nutrient delivery to affected areas.</p></li>
            <li><p class="body-paragraph">Break down scar tissue and adhesions.</p></li>
            <li><p class="body-paragraph">Accelerate recovery from injuries and chronic conditions.</p></li>
        </ul>

        <p class="body-paragraph">Whether you are seeking relief from stubborn heel pain, shoulder tendonitis, or other soft tissue injuries, shockwave therapy can offer safe, effective solutions to help you get back to doing the things you love.</p>

        `
    },
    {
        id:12,
        heading:"Cold Laser Therapy",
        url:"/service/cold-laser-therapy",
        code:`
        <h3 class="body-heading">Cold Laser Therapy</h3>
        <p class="body-paragraph">Cold laser therapy at True Life Wellness and Physiotherapy utilizes the TLC-2000 unit to emit low-level laser light to penetrate deep into tissues and promote healing at the cellular level. This non-invasive treatment modality offers a range of therapeutic benefits, including:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph">Pain relief: Cold laser therapy can help alleviate pain associated with various conditions, including arthritis, tendonitis, and fibromyalgia.</p></li>
            <li><p class="body-paragraph">Reduced inflammation: By targeting inflamed tissues, cold laser therapy can help reduce swelling and promote faster healing.</p></li>
            <li><p class="body-paragraph">Enhanced tissue repair: Cold laser therapy stimulates cellular activity, accelerating the body's natural healing processes and promoting tissue regeneration.</p></li>
            <li><p class="body-paragraph">Improved circulation: By increasing blood flow to the treated area, cold laser therapy can enhance nutrient delivery and waste removal, supporting overall tissue health.</p></li>
            <li><p class="body-paragraph">Accelerated recovery: Whether you are recovering from surgery, a sports injury, or a chronic condition, cold laser therapy can help speed up the healing process and shorten recovery time.</p></li>
        </ul>

        <p class="body-paragraph">With its gentle, non-invasive nature and minimal side effects, cold laser therapy is suitable for patients of all ages and can be used as a standalone treatment or in conjunction with other therapies to enhance results.</p>

        `
    },
    {
        id:13,
        heading:"Compression Apparel",
        url:"/service/compression-apparel",
        code:`
        <h3 class="body-heading">Compression Apparel</h3>
        <p class="body-paragraph">Compression apparel at True Life Wellness and Physiotherapy offers targeted support and therapeutic benefits for a variety of conditions affecting the circulatory and musculoskeletal systems. Our compression garments are designed to:</p>

        <ul class="custom-green-bullet-list">
            <li><p class="body-paragraph">Improve circulation: Compression apparel applies gentle pressure to the body, helping to promote blood flow and reduce the risk of blood clots, varicose veins, and other circulatory issues.</p></li>
            <li><p class="body-paragraph">Reduce swelling: By preventing fluid buildup in the limbs, compression apparel can help alleviate swelling and edema, improving comfort and mobility.</p></li>
            <li><p class="body-paragraph">Support muscle recovery: Compression garments provide support to muscles and joints, reducing fatigue and soreness during and after physical activity, and promoting faster recovery.</p></li>
            <li><p class="body-paragraph">Enhance athletic performance: Athletes can benefit from compression apparel's ability to improve circulation, reduce muscle vibration, and support proper biomechanics, leading to better endurance, performance, and recovery.</p></li>
            <li><p class="body-paragraph">Manage chronic conditions: Compression apparel can be used to manage symptoms of chronic conditions such as lymphedema, venous insufficiency, and chronic venous disorders, improving quality of life and reducing complications.</p></li>
        </ul>

        <p class="body-paragraph">Whether you are an athlete looking to enhance performance, a traveler seeking relief from swollen legs, or someone managing a chronic condition, our compression apparel offers comfortable, effective support to help you stay active, healthy, and pain-free.</p>

        `
    },
]

function KeyFeatureText({ind=1 }) {
    const [curElement , setCurElement] = useState(ind-1)
    useEffect(()=>{
        setCurElement(ind-1)

    },[ind])

    const navigate = useNavigate()
  return (
    <div className='KeyFeatureText-container standard-padding-space'>
        <div className='container'>
            <div className='row gy-3 flex-column-reverse flex-xl-row'>
                <div className='col-xl-5'>
                    <div className='d-flex flex-column main-box my-4 justify-content-center'>

                    {
                        changingTabData.map((ele,ind)=>{
                            return(
                                <div 
                                    className={`w-100 tab-box ${curElement === ind && "active-tab"} `}
                                    key={ele.id}
                                    onClick={()=>{setCurElement(ind);navigate(ele.url)}}
                                >
                                    <h4 
                                        className={`body-heading mb-0  ${curElement === ind && "active-title"} `} 
                                        >{ele.heading}
                                    </h4>
                                </div>
                        )
                    })
                }
                </div>
                <a href='tel:(416) 744-7417' style={{textDecoration:"none"}}>
                <div className='emergency-btn'>
                    <div className='d-flex align-items-center justify-content-start gap-3 py-3'>
                        <div>
                            <img src='/images/icons/emergency_btn.png' className='icon' alt='btn-icon'/>
                        </div>
                        <div className='text'>
                            <p className='body-paragraph mb-1' ><b>Emergency Cases</b></p>
                            <p className='body-paragraph mb-0' >(416) 744-7417</p>
                        </div>

                    </div>
                </div>
                </a>

                </div>
                <div className='col-xl-7'>
                    <div className='cont py-3' dangerouslySetInnerHTML={{__html:changingTabData[curElement].code}}></div>
                </div>

            </div>
                    
        </div>
    </div>
  )
}

export default KeyFeatureText