import React from 'react'
import "./TeamCards.css"
import TeamCardBox from './TeamCardBox'

const teamCardData = [
    {   
        img:"/images/icons/team-card-img-altaf-virani.webp",
        name:"Dr. Altaf Virani",
        desc:"Chiropractor"
    },
    {   
        img:"/images/icons/team-card-img-hemangi-modi.webp",
        name:"Hemangi Modi",
        desc:"Registered Physiotherapist"
    },
    {   
        img:"/images/icons/team-card-img-karess-flores.webp",
        name:"Karess Flores",
        desc:"Office Manager/Certified Compression Garment Fitter"
    },
    {   
        img:"/images/icons/team-card-img-fereshteh.webp",
        name:"Ms. fereshteh M.",
        desc:"Registered Physiotherapist"
    },
    {   
        img:"/images/icons/team-card-img-tetyana-nikishyna.webp",
        name:"Tetyana Nikishyna",
        desc:"Registered Massage Therapist"
    },
    {   
        img:"/images/icons/team-card-img-zahra-nensi.webp",
        name:"Zahra Nensi",
        desc:"Registered Acupuncturist"
    },
    {   
        img:"/images/icons/team-card-img-zubina-ladak.webp",
        name:"Zubina Ladak",
        desc:"Registered Psychological Associate"
    },
    {   
        img:"/images/icons/team-card-img-new.webp",
        name:"Parth B",
        desc:""
    },
    {   
        img:"/images/icons/team-card-img-Garima.webp",
        name:"Garima",
        desc:"Pelvic Floor Therapist"
    },
]

function TeamCards() {
  return (
    <div className='TeamCards-container standard-padding-space'>
        <div className='container'>
            <div className='row justify-content-center gy-4'>

                {teamCardData.map((ele)=>{
                    return(
                        <div className='col-xl-3 col-md-6'>
                            <TeamCardBox
                                icon={ele.img}
                                heading={ele.name}
                                content={ele.desc}
                            />
                        </div>
                    )
                })
                    
                }
            </div>
        </div>
    </div>
  )
}

export default TeamCards