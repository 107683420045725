import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import NewsList from '../component/NewsList/NewsList'
import { Helmet } from 'react-helmet'

function Blog() {
    return (
        <div className="home-page">
          <Helmet>
            <title>
            Discover Wellness Insights | True Life Wellness & Physiotherapy Blogs
            </title>
            <meta
              name="description"
              content="Explore our insightful wellness blogs covering physiotherapy, chiropractic treatment, acupuncture, and more. Your path to wellness starts here. Visit now!"
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="BLOGS "
            spanHeading=''
            Paragraph=""
            nextLineSpanHeading=""
            bgImg="/images/creatives/blog-hero-bg.jpg"
            btn={false}
            isCheveron={true}
            btnRef="/"
            txtStart={false}
          />
          
    <div className='right-gradient'>
        <NewsList/>
          
    </div>

    <div style={{height:"30px"}}>

    </div>
    
          <Footer/>
          </div>
      )
}

export default Blog