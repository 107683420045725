import React from 'react'
import "./ServiceCard.css"
import ServiceBox from "./serviceCardBox"

const serviceCardData  = [
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/physiotherypy",
        heading:"Physiotherapy ",
        content:"Our team of expert physiotherapists are trained in various manual therapies, pelvic floor physiotherapy, and the use of a wide range of modalities including shockwave therapy, cold laser therapy, etc. to address your specific needs and promote healing.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/chiropractic-treatment",
        heading:"Chiropractic Treatment ",
        content:"Experience hands-on care for spinal, pelvic, and joint disorders, supplemented with therapeutic exercise and lifestyle counseling.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/registered-massage-therapy",
        heading:"Registered Massage Therapy ",
        content:"Relax and rejuvenate with either a therapeutic massage or a sports massage tailored to reduce muscle tension and alleviate pain.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/acupuncture",
        heading:"Acupuncture ",
        content:"Unlock the benefits of acupuncture for pain relief, stress reduction, and improved energy flow.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/psychology",
        heading:"Psychological Counseling (Virtual) ",
        content:"Access compassionate support and guidance from our experienced counselors through virtual sessions to address mental health concerns and enhance well-being.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/naturopathic-medicine",
        heading:"Naturopathic Services Medicine ",
        content:"Explore holistic approaches to health and wellness with our naturopathic practitioner, incorporating natural remedies and lifestyle counseling for optimal health.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/custom-made-orthotics-and-shoes",
        heading:"Custom Made Orthotics and Shoes ",
        content:"Address misalignments, improve posture, and alleviate pain with custom-made orthotics and shoes.  ",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/custom-made-braces",
        heading:"Custom Measured Braces ",
        content:"Help in the management of common conditions such as patellar tendinitis (jumper’s knee), lateral epicondylitis (tennis elbow), etc. ",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/pelvic-floor-physiotherapy",
        heading:"Pelvic Floor Physiotherapy ",
        content:"Specialized treatment for pelvic floor dysfunction, tailored to improve muscle strength, function, and overall pelvic health.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/concussion-rehabilitation",
        heading:"Concussion Rehabilitation ",
        content:"Comprehensive rehabilitation programs designed to support recovery from concussions, focusing on symptom management and gradual return to daily activities.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/shockwave-therapy",
        heading:"Shockwave Therapy ",
        content:"Effective treatment for various conditions including plantar fasciitis, heel spurs, tendinitis, and trigger points, promoting pain relief and accelerated healing.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/cold-laser-therapy",
        heading:"Cold Laser Therapy ",
        content:"Non-invasive treatment to reduce pain and inflammation, accelerate tissue repair, and enhance overall recovery.",
    },
    {
        icon:"/images/icons/service-card-img.png",
        anchor:"/service/compression-apparel",
        heading:"Compression Apparel ",
        content:"Explore our selection of specialized compression garments designed to provide support, increase blood circulation, and aid in recovery from various conditions.",
    }
];


function ServiceCard() {
  return (
    <div className='ServiceCard-container standard-padding-space'>
        <div className='container'>
            <div className='row gy-4 justify-content-center'>
                {
                    serviceCardData.map((ele)=>{
                    return(
                        <div className='col-xxl-3 col-lg-4 col-md-6 '>
                            <ServiceBox
                                icon={ele.icon}
                                heading={ele.heading}
                                content={ele.content}
                                anchor={ele.anchor}
                            />
                        </div>
                    )
                    })
                }

            </div>

        </div>
    </div>
  )
}

export default ServiceCard