import { useState } from "react";
import "./ImgModal.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

export default function Modal(props) {

  const [curImg,setCurImg] = useState(props.showImg);

  console.log("props =>" , props);

  function arrangeArrayWithFlag(arr, flag) {
    const flagIndex = arr.indexOf(flag);
  
    if (flagIndex === -1) {
      console.log(flagIndex);
      return arr;
    }
  
    const beforeFlag = arr.slice(flagIndex);
    const afterFlag = arr.slice(0, flagIndex);
  
    return [...beforeFlag, ...afterFlag];
  }

  const arrangedArray = arrangeArrayWithFlag(props.allImages , curImg)
  return (
    // <div className="img-modal-container" onClick={props.closeModal} style={props.status ? {display : "block"} : {display:"none"}}>
    //   <div className="overlay" ></div>
    //   <div className="modal-main d-flex justify-content-center align-items-center w-fit">
    //     <img src={curImg} alt="" className=" modal-img"/>
    //       {/* <Swiper
    //         spaceBetween={50}
    //         loop={true}
    //         slidesPerView={1}
    //         className="swiper py-3 mx-auto"
    //         >
    //           {
    //             arrangedArray.map((ele , ind)=>{
    //                 return(
    //                   <SwiperSlide>
    //                     <img src={ele} alt="" className=" modal-img"/>
    //                   </SwiperSlide>
    //                 )
    //             })
    //           }
    //       </Swiper> */}
    //     </div>
    // </div>
    <div className="dialog-bg d-md-block d-none">
          <div className="dialog-overlay" onClick={props.closeModal}></div>
          <div
            className="dialog"
            style={{ position: 'absolute',top:"50%",left:"50%",transform:"translate(-50%, -50%)" }}
          
            onClick={props.closeModal}
          >
            <img
              className="image"
              src={curImg}
              onClick={props.closeModal}
              alt="no"
            />
          </div>
      </div>
    );
}