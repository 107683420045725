import "./Testimonials.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from "swiper/modules";
import TestimonialCard from "./TestimonialCard";

function Testimonials() {
  const blogData = [
    {
      count: 1,
      clientName: "B Scook",
      clientImg: "/images/icons/user-img.png",
      clientReview:
        "Had my first ever chiropractic appointment with Altaf. He was great. Very detailed analysis of my medical history, some physical tests of my reflexes, physical strengths, pain thresholds, and specific concerns that lead me to seek out is help. He adapted a treatment programme, based upon his experience, for my very specific needs. He also gave me some potentially life-saving advice, with respect to some bad habits I've taken up. Strongly recommend.",
    },
    {
      count: 2,
      clientName: "Andrew Lemieux",
      clientImg: "/images/icons/user-img.png",
      clientReview:
        "I met with Dr. Virani last week and it was a great visit. He takes the time to explain everything he's doing and why he's doing it. He also encourages questions which I really appreciated. I learned a lot just from 1 visit. If you have any type of issue with your feet, I highly recommend reaching out to Dr. Virani. He will guide you in the right direction for what you need.",
    },
    {
      count: 3,
      clientName: "Grace Vitone",
      clientImg: "/images/icons/user-img.png",
      clientReview:
        "I love this clinic and all the practitioners. Everybody is so kind and take their time to listen to what i say and how I feel. Parth, the RMT, knows exactly how to target the areas that are bothersome, and I feel instant relief that lasts for weeks. He has helped me tremendously since I have started to visit him. The staff is also very nice and warm and it makes me feel as if we are a part of a family, and I look forward to continue coming back here for any issues I may have in the future.",
    },
  ];

  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider-tes').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider-tes').swiper;
    swiperInstance.slideNext();
  };

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="container">
    <div className="row d-flex align-items-center">

      <div className="col-lg-7 col-md-12 mb-3 text-center text-lg-start">
          <div className="text-content mx-auto mx-lg-0">
              <p className="sub-heading">Testimonials</p>
              <h3 className="body-heading">What our patients say about us</h3>
              <a href="https://www.google.com/maps/place/True+Life+Wellness+and+Physiotherapy/@43.756613,-79.5697746,17z/data=!4m8!3m7!1s0x882b3bb85665cbef:0x966597d65177984d!8m2!3d43.756613!4d-79.5697746!9m1!1b1!16s%2Fg%2F11f6111ws3?entry=ttu" target="blank">
                <button className="genral-btn my-md-3 my-3 mb-4">Read More</button>
              </a>
          </div>
      </div>
      <div className="col-lg-5 col-md-12">

        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="testimonials-list"
            id="articles-cards-slider-tes"
            >
              {
                blogData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview} count={clientData.count} total={blogData.length} handleNextButtonClick={handleNextButtonClick} handlePrevButtonClick={handlePrevButtonClick}/>
                    </SwiperSlide>
                     </>)
                })
              }
        </Swiper>
        
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials