import React,{useState} from 'react'
import "./GalleryGrid.css"
import ImgModal from "../ImgModal/ImgModal"

const gallImgUrl = [
    "/images/creatives/gall-img-1.png",
    "/images/creatives/gall-img-2.png",
    // "/images/creatives/gall-img-3.png",

    
    "/images/creatives/gall-img-4.webp",
    "/images/creatives/gall-img-3.png",
    "/images/creatives/gall-img-5.webp",

    "/images/creatives/gall-img-7.jpg",
    "/images/creatives/gall-img-8.jpg",
    "/images/creatives/gall-img-9.jpg",
    "/images/creatives/gall-img-10.jpg",
]

function GalleryGrid() {
    const [modal, setModal] = useState(false);
    const [imgSrc , setImgSrc] = useState("")

    const toggleModal = (e) => {
        setImgSrc(e.target.id)
        setModal((prev)=>{
            return !prev
        });
    };
    
  return (
    <div className='GalleryGrid-container'>
        {modal ? <ImgModal status={modal} showImg={imgSrc} allImages={gallImgUrl} closeModal={toggleModal} /> : <></>}

        <div className='container'>
            <div className='row gy-4'>
                {
                    gallImgUrl.map((ele)=>{
                        return(
                        <div className='col-xl-4'>
                            <div className='img-card'
                             id={ele} 
                             onClick={toggleModal}
                             style={{backgroundImage:`url(${ele})`}}></div>
                        </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default GalleryGrid