import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TestimonialForm from '../component/TestimonialForm/TestimonialForm'
import TeamCards from '../component/TeamCards/TeamCards'
import TeamHelpCall from '../component/TeamHelpCall/TeamHelpCall'
import { Helmet } from 'react-helmet'

function Team() {
    return (
        <div className="home-page">
          <Helmet>
            <title>
            True Life Wellness: Book Your Appointment Today
            </title>
            <meta
              name="description"
              content="Experience expert care at True Life Wellness & Physiotherapy. Book now for physiotherapy, chiropractic, massage therapy & more. Call us!"
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            preSpanHeading="Registered"
            Heading=" Providers"
            spanHeading=''
            Paragraph="Select A Member Below To Learn More About Their Experience And Expertise!"
            nextLineSpanHeading=""
            bgImg="/images/creatives/team-hero-bg.jpg"
            btn={false}
            isCheveron={true}
            btnRef="/"
            txtStart={false}
          />
          
    <div className='right-gradient'>
      <div className='left-gradient'>
          <TeamCards/>
          <TeamHelpCall 
            borderBottom={false}
          />
    </div>
    
    </div>
          <TestimonialForm marginBottom={false}/>
    
    
          <Footer/>
          </div>
      )
}

export default Team