import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'

function Thank() {
  return (
    <div>
      
         <HeroBanner 
            subHeading=""
            preSpanHeading=""
            Heading=" Thank"
            spanHeading=' You'
            Paragraph="Your form had been submited. We would reach to you soon!"
            nextLineSpanHeading=""
            bgImg="/images/creatives/thank-you-hero-bg.jpg"
            btn={false}
            isCheveron={false}
            txtStart={false}
            bannerMarginBottom={true}
          />

          <Footer/>

    </div>
  )
}

export default Thank