import React from 'react'
import "./WhyUsBanner.css"

const cardContent = [
  {
    icon:"/images/icons/card-Experienced-Practitioners-icon.webp",
    title:"Experienced Practitioners",
    content:"With years of experience and expertise in their respective fields, our registered healthcare practitioners are dedicated to delivering high-quality care and achieving optimal outcomes for our patients."
  },
  {
    icon:"/images/icons/card-Multilengual-Staff-icon.webp",
    title:"Multilingual Staff",
    content:"Communication is key to effective treatment. Our staff is fluent in English, Tagalog, Gujrati, and Farsi, ensuring that language barriers are not a hindrance to receiving the care you deserve."
  },
  {
    icon:"/images/icons/card-State-of-the-art-Facility-icon.png",
    title:"State-of-the-Art Facility",
    content:"Our clinic is equipped with the latest technology and advanced therapeutic modalities to provide you with cutting-edge treatments and the highest standard of care."
  },
]

function WhyUsBanner() {
  return (
    <div className='WhyUsBanner-container standard-padding-space'>
        <div className='container'>
            <div className='text-content text-center my-4'>
              <h6 className='sub-heading'>Comprehensive Care for Your Well-being</h6>
              <h3 className='body-heading'>Why Choose Us?</h3>
            </div>
            <div className='card-container container-fluid pt-4'>
              <div className='row gy-4'>
                {
                  cardContent.map((ele)=>{
                    return(
                        <div className=' col-lg-4'>
                          <div className='card-box d-flex flex-column justify-content-center align-items-center gap-2 px-3 my-3' >

                            <img src={ele.icon} alt='card-icon' className='card-icon'/>
                            <div className='card-content text-center mt-3'>
                              <h5 className='title'>{ele.title}</h5>
                              <p className='body-paragraph px-2'>{ele.content}</p>
                            </div>
                          </div>

                        </div>
                    )
                  })
                }

              </div>
              

            </div>
        </div>
    </div>
  )
}

export default WhyUsBanner