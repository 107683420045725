import './App.css';
import "./bootstrap.min.css"
import About from './screens/About';
import Home from './screens/Home';
import { Routes, Route ,Navigate} from 'react-router-dom';
import Service from './screens/Service';
import Physiotherapy from './screens/Physiotherapy';
import Team from './screens/Team';
import Gallery from './screens/Gallery';
import Form from './screens/Form';
import Blog from './screens/Blog';
import Contact from './screens/Contact';
import BlogInner from "./screens/newsInner"
import Thank from './screens/Thank';
import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./component/LocationPages_/LocationPages"
import NotFound from './screens/NotFound';


function App() {
  return (
    <div style={{backgroundColor:"white"}}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" replace={true} />}/>
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />


        <Route path="/service/:id" element={<Physiotherapy />} />
        {/* <Route path="/physiotherapy" element={<Physiotherapy />} /> */}
       
        <Route path="/registered-providers" element={<Team />} />
        {/* <Route path="/form" element={<Form />} /> */}

        <Route path="/gallery" element={<Gallery />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blogs/:id" element={<BlogInner />} />


        <Route path="/contact" element={<Contact />} />
        <Route path="/thank-you" element={<Thank />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        

        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>


        <Route path='/*' element={<NotFound />}/>

      </Routes>
    </div>
  );
}

export default App;
