import React, { useState } from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import Footer from '../component/Footer/Footer'
import TestimonialForm from '../component/TestimonialForm/TestimonialForm'
import ServiceCard from '../component/ServiceCard/ServiceCard'
import { Helmet } from 'react-helmet'

function Service() {
    return (
        <div className="home-page">
          <Helmet>
            <title>
            Revitalize Your Well-Being with True Life Wellness & Physiotherapy services.
            </title>
            <meta
              name="description"
              content="Discover expert care in North York. Physiotherapy, chiropractic treatment, massage therapy services, and more. Book your appointment now"
            ></meta>
          </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="Our "
            spanHeading='Services'
            Paragraph="True Life Wellness and Physiotherapy is here to support you every step of the way."
            nextLineSpanHeading=""
            bgImg="/images/creatives/service-hero-bg.jpg"
            btn={false}
            isCheveron={true}
            btnRef="/"
            txtStart={false}
          />
          
    <div className='right-gradient'>
      <div className='left-gradient'>
          <ServiceCard/>
          
    </div>
    
    </div>
          <TestimonialForm marginBottom={false}/>
    
    
          <Footer/>
          </div>
      )
}

export default Service