import React from 'react'
import "./ServicesCarasoul.css"
import { Autoplay,Pagination  } from "swiper/modules";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import ServiceCard from './serviceCard';

function ServicesCarasoul({
    subHeading="",
    heading="",
    content="",
    serviceData=[],
    marginTopRemoval=false
}) {



    const handlePrevButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slideNext();
      };
  return (
    <div className='ServicesCarasoul-container standard-padding-space' 
        style={marginTopRemoval? {paddingTop:"0"}:{}}
    >
        <div className='container'>

            <div className='text-content text-center mb-3'>

                <h6 className='sub-heading'>{subHeading}</h6>
                <h3 className='body-heading text-center mb-0'>{heading}</h3>
                
                <p className='body-paragraph px-2'>{content}</p>


            </div>

        

                <Swiper
                    spaceBetween={50}
                    pagination={true}
                    autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    }}
                    loop={true}
                    navigation={
                        {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev',
                        }
                        }
                    breakpoints={{
                        200:{
                        slidesPerView: 1,
                        },
                        1100: {
                        slidesPerView: 3,
                        },
                        1400: {
                        slidesPerView: 4,
                        }
                    }}
                    modules={[Autoplay,Pagination]}
                    // modules={[Pagination]}
                    observer ={true}
                    observeParents={true}
                    parallax={true}
                    className=" my-5 py-4 px-4"
                    // style={{position:"relative"}}
                    id="service-cards-slider"
                    >
                    
                    
                {/* <div className="swiper-button-prev swiper-button d-lg-block d-none" onClick={handlePrevButtonClick}> 
                <img src="/images/icons/indus-carousel-arrow-left.svg" className="arrow-img"/>
                </div> */}
                {
                        serviceData.map((ele,ind)=>{
                        return(<>
                            <SwiperSlide>
                                <ServiceCard
                                    icon={ele.icon}
                                    heading={ele.heading}
                                    content={ele.content}
                                    anchor={ele.anchor}
                                />
                            </SwiperSlide>
        
                            </>)
                        })
                    }
                    {/* <div className="swiper-button-next swiper-button d-lg-block d-none" onClick={handleNextButtonClick}>
                    <img src="/images/icons/indus-carousel-arrow-right.svg" className="arrow-img"/>
                    </div> */}
                </Swiper>
                </div>
    </div>

  )
}

export default ServicesCarasoul