import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TeamHelpCall from '../component/TeamHelpCall/TeamHelpCall'
import Footer from '../component/Footer/Footer'
import { Helmet } from 'react-helmet'

function Contact() {
  return (
    <div>
        <Helmet>
            <title>
            Expert Physiotherapy Services - True Life Wellness
            </title>
            <meta
              name="description"
              content="Transform your health with our expert physiotherapy services. Personalized care for optimal well-being. Book now! Located in North York."
            ></meta>
          </Helmet>
         <HeroBanner 
            subHeading=""
            Heading="Need help with OUR SERVICES? "
            spanHeading='Let’s talk!'
            nextLineSpanHeading=""
            Paragraph="We strive to preserve dignity and enhance the quality of life for our patients and their loved ones who are facing a decrease in independence."
            btnText=""
            bgImg="/images/creatives/contact-hero-bg.jpg"
            btn={false}
            serviceForm={true}
            socialLinks={true}
            isCheveron={false}
            btnRef="/"
            txtStart={false}
          />
            <div className='container standard-padding-space'>
                <iframe className='contact-map' src="https://maps.google.com/maps?q=True%20Life%20Wellness%20and%20Physiotherapy%2C%20North%20York&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="True Life Wellness and Physiotherapy, North York" aria-label="True Life Wellness and Physiotherapy, North York"></iframe>
            </div>

            <TeamHelpCall
                borderBottom={true}
            />

            <Footer/>
    </div>
  )
}

export default Contact