import React from 'react'
import HeroBanner from '../component/Common Banner Component/HeroBanner'
import TextContent from '../component/TextContent/TextContent'
import Footer from '../component/Footer/Footer'
import TestimonialForm from '../component/TestimonialForm/TestimonialForm'
import VideoContent from '../component/VideoContent/VideoContent'
import { Helmet } from 'react-helmet'

const text1List = [
   "Customized treatment plans tailored to your specific needs.",
   "Utilization of evidence-based techniques and modalities.",
   "Manual therapies, therapeutic exercises, and various pain management modalities.",
   "Recovery from injury, management of chronic pain, and improvement of mobility.",
   "Support for post-surgery rehabilitation and sports injury recovery.",
   "Treatment for pelvic floor physio therapy.  ",
]


const text2List = [
   "Hands-on adjustments and manipulations to restore proper alignment.",
   "Alleviation of back and neck pain, headaches, jaw pain, and various other musculoskeletal issues.",
   "Optimization of the spine and nervous system health.",
   "Enhancement of the body's natural healing processes.",
   "Personalized care to address individual health goals.",
   
]
const text3List = [
   "Various massage techniques including Swedish, deep tissue, and sports massage.",
   "Reduction of muscle tension, stress, and pain.",
   "Promotion of circulation and overall well-being.",
   "Recovery aid for injury rehabilitation.",
   "Relaxation and relief from everyday aches and pains.",
   
]
const text4List = [
   "Reduction of leg discomfort, swelling, and edema.",
   "Improvement of circulation and alleviation of symptoms associated with vascular conditions.",
   "Varied styles and compression levels to suit individual needs.",
   "Relief for tired, achy legs and prevention of complications.",
   "Support for recovery from surgery and management of chronic conditions.",
   
]

const text5List = [
   "Non-invasive treatment for musculoskeletal conditions such as plantar fasciitis, heel spurs, and tendonitis.",
   "Stimulation of natural healing processes and tissue regeneration.",
   "Reduction of inflammation and pain.",
   "Customized treatment plans based on individual assessment.",
   "Effective relief for sports injuries, repetitive strains, and degenerative conditions such as arthritis.",
   
]
const text6List = [
   "Assessment of foot structure and gait patterns.",
   "Creation of custom made orthotic inserts for optimal support and alignment.",
   "Selection of high-quality footwear compatible with orthotics.",
   "Improvement of posture and alleviation of foot-related pain.",
   "Enhanced comfort and mobility for daily activities.",
   
]

const text7List = [
   "Custom measurements for knee, ankle, and back braces, etc.  ",
   "Address complaints such as patellar tendinitis (jumper’s knee), lateral epicondylitis (tennis elbow), etc. with a custom measured brace to reduce the pain during activity.  ",

]

function About() {
    return (
        <div className="home-page">
         <Helmet>
         <title>
         True Life Wellness & Physiotherapy: Expert Care in North York.
         </title>
         <meta
            name="description"
            content="Book your appointment with True Life Wellness & Physiotherapy today! Comprehensive rehabilitation services tailored to your needs. "
         ></meta>
         </Helmet>
          <HeroBanner 
            subHeading=""
            Heading="About"
            spanHeading=' True Life Wellness & Physiotherapy'
            nextLineSpanHeading=""
            Paragraph="Our mission is to create a warm and caring environment where every patient feels heard and supported on their journey to better health"
            btnText=""
            bgImg="/images/creatives/about-hero-bg.jpg"
            btn={false}
            isCheveron={true}
            btnRef="/"
            txtStart={false}
          />
          
    <div className='right-gradient'>
      <div className='left-gradient'>
          
          <TextContent
             heading="Who We Are?"
             subHeading= "About Us"
             content={"True Life Wellness and Physiotherapy, is dedicated to providing comprehensive rehabilitation services to help you achieve your optimal health and wellness. Located conveniently on Islington Avenue between Finch Avenue and Steeles Avenue in North York, Ontario, our clinic offers a range of therapies including Physiotherapy, Pelvic Floor Physiotherapy, Chiropractic Treatment, Registered Massage Therapy, Psychological Counseling, Cold Laser Therapy, Shock Wave Therapy, Acupuncture, Naturopathic Medicine, Custom Made Orthotics and Custom Bracing, and Compress Apparel.  With a commitment to personalized care, our team of registered health care practitioners develops customized treatment plans tailored to your specific needs."}
             btn={false}
             shiftRow={false}
             marginTop={true}
             img="/images/creatives/home-text-1.webp"
             reviewContent={false}
          />
          <TextContent
             heading="A Multi-Lingual Team"
             subHeading=""
             content={"Communication is key to effective treatment. Our staff is fluent in English, Tagalog, Gujrati, and Farsi, ensuring that language barriers are not a hindrance to receiving the care you deserve. \n Our newly renovated, larger facility located on Islington Avenue, between Finch Avenue and Steeles Avenue, is less than 10 minutes away from our previous location.  With extended hours and weekend and virtual appointments available, we strive to make accessing quality care as convenient as possible for our patients. "}
             btn={true}
             btnText={"Get Consultation"}
             btnRef='tel'
             btnIcon={true}
             shiftRow={true}
             marginTop={true}
             img="/images/creatives/home-text-1.webp"
             reviewContent={false}
          />
          <VideoContent
            img1={"/images/creatives/video-card-img.png"}
            img2={"/images/creatives/video-card-img.png"}
            videoSrc1={"/images/video-content-1.mp4"}
            videoSrc2={"/images/video-content-1.mp4"}
          />
          <TextContent
             heading="Physiotherapy"
             subHeading=""
             content={""}
             postBtn={true}
             btnText={"Book Appointment"}
             btnRef='jnape'
             btnIcon={true}
             shiftRow={true}
             marginTop={true}
             img="/images/creatives/about-text-3.png"
             reviewContent={false}
             listPoint={text1List}
             listPointList={true}
          />
          <TextContent
             heading="Chiropractic"
             subHeading=""
             content={""}
             postBtn={true}
             btnText={"Book Appointment"}
             btnRef='jnape'
             btnIcon={true}
             shiftRow={false}
             marginTop={true}
             img="/images/creatives/about-text-4.png"
             reviewContent={false}
             listPoint={text2List}
             listPointList={true}
          />
          <TextContent
             heading="Massage Theraphy"
             subHeading=""
             content={""}
             postBtn={true}
             btnText={"Book Appointment"}
             btnRef='jnape'
             btnIcon={true}
             shiftRow={true}
             marginTop={true}
             img="/images/creatives/about-text-5.png"
             reviewContent={false}
             listPoint={text3List}
             listPointList={true}
          />
          <TextContent
             heading="Custom Made Orthotics and Shoes"
             subHeading=""
             content={""}
             postBtn={true}
             btnText={"Book Appointment"}
             btnRef='jnape'
             btnIcon={true}
             shiftRow={false}
             marginTop={true}
             img="/images/creatives/about-text-6.1.webp"
             reviewContent={false}
             listPoint={text6List}
             listPointList={true}
          />
          <TextContent
             heading="Custom Measured Braces"
             subHeading=""
             content={""}
             postBtn={true}
             btnText={"Book Appointment"}
             btnRef='jnape'
             btnIcon={true}
             shiftRow={true}
             marginTop={true}
             img="/images/creatives/about-text-9.webp"
             reviewContent={false}
             listPoint={text7List}
             listPointList={true}
          />
          <TextContent
             heading="Compression Stockings"
             subHeading=""
             content={""}
             postBtn={true}
             btnText={"Book Appointment"}
             btnRef='jnape'
             btnIcon={true}
             shiftRow={false}
             marginTop={true}
             img="/images/creatives/about-text-7.png"
             reviewContent={false}
             listPoint={text4List}
             listPointList={true}
          />
          <TextContent
             heading="Shockwave Theraphy"
             subHeading=""
             content={"Effective Treatment For:"}
             postBtn={true}
             btnText={"Book Appointment"}
             btnRef='jnape'
             btnIcon={true}
             shiftRow={true}
             marginTop={true}
             img="/images/creatives/about-text-8.png"
             reviewContent={false}
             adjecentlistPoint={text5List}
             adjecentlistPointList={true}
          />
          {/* <TextContent
             heading="Nutritional Counceling"
             subHeading=""
             content={"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,\nLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"}
             postBtn={true}
             btnText={"Get Consultation"}
             btnRef='tel'
             btnIcon={true}
             shiftRow={true}
             marginTop={true}
             img="/images/creatives/about-text-9.png"
             reviewContent={false}
          /> */}
    </div>
      
    
    </div>
        
          <TestimonialForm marginBottom={false}/>
    
    
          <Footer/>
          </div>
      )
}

export default About